import PropTypes from 'prop-types';

const shape = PropTypes.shape({
  id: PropTypes.number,
  title: PropTypes.string,
  cover: PropTypes.string,
  link: PropTypes.string,
});

const preset = {
  id: 1,
  title: '文章標題文章標題文章標',
  cover: 'https://via.placeholder.com/1920x1080.png',
  link: 'https://via.placeholder.com/1920x1080.png',
};

export default {
  shape,
  preset,
};
