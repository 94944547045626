/* eslint-disable implicit-arrow-linebreak */
/* eslint-disable max-len */
import React from 'react';
import styled from 'styled-components';
import InnovationPostItem from './InnovationPostItem';
import MainContainer from '../Universal/Container/MainContainer';
import POST1 from '../../assets/images/article-image-01.jpg';
import POST2 from '../../assets/images/article-image-02.jpg';
import POST3 from '../../assets/images/article-image-03.jpg';
import POST4 from '../../assets/images/article-image-04.jpg';
import { BREAK_POINT_MOBILE, BREAK_POINT_TABLET } from '../../assets/layout';

const Wrapper = styled(MainContainer)`
  display: flex;
  flex-wrap: wrap;
  padding-top: 1rem;
  padding-bottom: 8.5rem;
  justify-content: space-around;
  padding-left: 2.5rem;
  padding-right: 2.5rem;
  @media only screen and (min-width: ${BREAK_POINT_MOBILE}) {
    padding-left: 0;
    padding-right: 0;
  }
  @media only screen and (min-width: ${BREAK_POINT_TABLET}) {
    padding-left: 1rem;
    padding-right: 1rem;
  }
`;

const POSTS = [
  {
    id: 1,
    title: '回應育兒首要需求！看各國政府如何推動平價公共托育政策',
    cover: POST1,
    link: 'https://bit.ly/3nmbjlJ',
  },
  {
    id: 2,
    title:
      '經濟壓力大，年輕人不敢想像有小孩的未來——政府企業齊相挺，祭津貼補助緩解負擔',
    cover: POST2,
    link: 'https://bit.ly/37lUiTb',
  },
  {
    id: 3,
    title: '工作、育兒只能二選一？促進友善職場環境，讓公司成為員工生育的後盾',
    cover: POST3,
    link: 'https://bit.ly/34bBqEe',
  },
  {
    id: 4,
    title:
      '當男性請育嬰假成為日常、女性可自選生育年齡——翻轉傳統思維讓育兒之路更平權',
    cover: POST4,
    link: 'https://bit.ly/2WmEQj3',
  },
];

function InnovationPosts() {
  const renderPosts = () =>
    POSTS.map((item) => (
      <InnovationPostItem key={`post-${item.id}`} data={item} />
    ));

  return <Wrapper>{renderPosts()}</Wrapper>;
}

export default InnovationPosts;
