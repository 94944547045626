/* eslint-disable max-len */
import React, { useState } from 'react';
import styled from 'styled-components';
import { H4 } from '../Universal/Title/H';
import InnovationPostType from '../../propTypes/InnovationPostType';
import { BREAK_POINT_MOBILE, BREAK_POINT_TABLET } from '../../assets/layout';
import color from '../../assets/color';

const Block = styled.a`
  width: 100%;
  padding: 1rem;
  background: #fbfafa;
  padding-bottom: 4rem;
  border: #fbfafa solid 1px;
  margin-bottom: 1rem;
  position: relative;
  cursor: pointer;
  border-radius: 4px;

  @media only screen and (min-width: ${BREAK_POINT_MOBILE}) {
    width: 38%;
  }
  @media only screen and (min-width: ${BREAK_POINT_TABLET}) {
    width: 43%;
  }
`;

const Title = styled(H4)`
  line-height: 1.5;
`;

const Cover = styled.div`
  height: 18rem;
  width: 100%;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  background-image: ${(props) => `url(${props.cover})` || ''};
  margin-bottom: 2.1rem;
`;

const More = styled.div`
  position: absolute;
  right: 1rem;
  bottom: 1rem;
  padding-bottom: 0.5rem;
  border-bottom: #fbfafa solid 1px;
`;

function InnovationPostItem({ data }) {
  const { title, cover, link } = data;
  const [isHover, setIsHover] = useState(false);

  const LinkHover = {
    borderBottom: `${color.primary} solid 1px`,
  };

  const BlockHover = {
    boxShadow: '0 4px 12px 0 #d8d8d8',
  };

  const handleMouseEnter = () => {
    setIsHover(true);
  };

  const handleMouseLeave = () => {
    setIsHover(false);
  };

  return (
    <Block
      href={link}
      target="_blank"
      rel="noreferrer"
      style={isHover ? BlockHover : {}}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
    >
      <Cover cover={cover} />
      <Title>{title}</Title>
      <More style={isHover ? LinkHover : {}}>繼續閱讀</More>
    </Block>
  );
}

InnovationPostItem.propTypes = {
  data: InnovationPostType.shape,
};

InnovationPostItem.defaultProps = {
  data: InnovationPostType.preset,
};

export default InnovationPostItem;
