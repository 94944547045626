/* eslint-disable implicit-arrow-linebreak */
/* eslint-disable max-len */
import React from 'react';
import styled from 'styled-components';
import background from '../../assets/images/banner_action.png';
import backgroundMobile from '../../assets/images/banner_action_mobile.png';
import backgroundTablet from '../../assets/images/banner_action_tablet.png';
import { BREAK_POINT_MOBILE, BREAK_POINT_TABLET } from '../../assets/layout';
import { H2 } from '../Universal/Title/H';
import MainContainer from '../Universal/Container/MainContainer';

const Wrapper = styled.div`
  padding: 0rem 2.5rem;
  background-image: url(${backgroundMobile});
  background-position: center top;
  background-size: 100% 100%;
  background-repeat: no-repeat;
  border: #fbfafa solid 1px;
  margin-bottom: 1rem;
  @media only screen and (min-width: ${BREAK_POINT_MOBILE}) {
    background-image: url(${backgroundTablet});
  }
  @media only screen and (min-width: ${BREAK_POINT_TABLET}) {
    background-image: url(${background});
    padding: 0;
  }
`;

const Content = styled.div`
  margin-top: 4.5rem;
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  @media only screen and (min-width: ${BREAK_POINT_MOBILE}) {
    margin-top: 8.4rem;
  }
`;

const Depiction = styled.p`
  max-width: 45rem;
  margin-top: 1rem;
  text-align: justify;
  text-justify: inter-ideograph;
`;

function InfographicBanner() {
  return (
    <Wrapper>
      <MainContainer>
        <Content>
          <H2>新知分享：國內外創新行動大匯集</H2>
          <Depiction>少子女化現象並非臺灣獨有，各國的政府、企業、民間如何面對問題、找尋解方？透過 4 大熱門話題——公共托育、津貼補助、友善職場、文化面向，一同看看國內外不同部門如何採取行動、促進社會進步。</Depiction>
        </Content>
      </MainContainer>
    </Wrapper>
  );
}

export default InfographicBanner;
