import React from 'react';
import SEO from '../components/Universal/SEO';
import MainLayout from '../layout/MainLayout';
import Contact from '../components/Contact/Contact';
import InnovationBanner from '../components/Innovation/InnovationBanner';
import InnovationPosts from '../components/Innovation/InnovationPosts';

const DESC = '永齡基金會與社企流攜手，打造明日戶政所，提供少子女化議題現況、彙整政府資源與國內外創新行動。想知道各國的政府、企業、民間如何面對問題、找尋解方嗎？透過 4 大熱門話題——公共托育、津貼補助、友善職場、文化面向，一同看看國內外不同部門如何採取行動、促進社會進步。';

function InnovationPage() {
  return (
    <MainLayout>
      <SEO title="創新行動 | 明日戶政所——少子女化全解析" description={DESC} />
      <InnovationBanner />
      <InnovationPosts />
      <Contact />
    </MainLayout>
  );
}

export default InnovationPage;
